/* eslint-disable max-len */
<template>
  <div v-if="this.$route.matched.length > 2" class="animated fadeIn">
    <router-view />
  </div>
  <div v-else class="animated fadeIn">
    <b-card no-body>
      <b-card-header v-b-toggle.filterBody> Фильтр </b-card-header>
      <b-collapse id="filterBody" visible class="card-body">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group class="form-group-el-select" label="Код рейса">
                <el-select-clearable
                  v-model="filter.route_id"
                  class="form-control"
                  name="route-code"
                  placeholder="Начните вводить код рейса"
                  :multiple="true"
                  clearable
                  :filterable="true"
                  :remote="true"
                  reserve-keyword
                  :remote-method="customersAuctionsRoutesList"
                  :loading="loading"
                  :disabled="filter.category === 'cancelled'"
                  @input="updateFilter('route_id')"
                  @clear="customersAuctionsRoutesList"
                >
                  <el-option
                    v-for="item in routeIds"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select-clearable>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group class="form-group-el-select" label="Тип перевозки">
                <el-select
                  v-model="filter.transportation_id"
                  class="form-control"
                  name="transportation_id"
                  placeholder=""
                  :loading="loading"
                  :disabled="filter.category === 'cancelled'"
                  @input="updateFilter('transportation_id')"
                >
                  <el-option
                    v-for="item in types"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group class="form-group-el-select" label="Статус">
                <el-select-clearable
                  v-model="filter.status"
                  class="form-control"
                  name="status"
                  placeholder=""
                  :multiple="true"
                  :loading="loading"
                  :disabled="
                    filter.category === 'drafts' ||
                    filter.category === 'graduated' ||
                    filter.category === 'cancelled'
                  "
                  @input="updateFilter('status')"
                >
                  <el-option
                    v-for="item in statuses"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select-clearable>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="Группа ответственных"
              >
                <el-select-clearable
                  v-model="filter.stl_id"
                  class="form-control"
                  name="manager"
                  placeholder="Начните вводить название"
                  :multiple="true"
                  clearable
                  :filterable="true"
                  :remote="true"
                  reserve-keyword
                  :remote-method="customersRoutesManagersList"
                  :loading="loading"
                  @input="updateFilter('stl_id')"
                  @clear="customersRoutesManagersList"
                >
                  <el-option
                    v-for="item in managers"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select-clearable>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group class="form-group-el-select" label="Перевозчик">
                <el-select-clearable
                  v-model="filter.contractor"
                  class="form-control"
                  name="contractor"
                  placeholder="Начните вводить название компании перевозчика"
                  :multiple="true"
                  clearable
                  :filterable="true"
                  :remote="true"
                  reserve-keyword
                  :remote-method="customersAuctionsContractorsList"
                  :loading="loading"
                  :disabled="
                    filter.category === 'drafts' ||
                    filter.category === 'cancelled'
                  "
                  @input="updateFilter('contractor')"
                  @clear="customersAuctionsContractorsList"
                >
                  <el-option
                    v-for="item in contractors"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select-clearable>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Плановая дата прибытия от">
                <el-date-picker-input
                  v-model="filter.arrival_date_from"
                  type="datetime"
                  autocomplete="false"
                  format="dd.MM.yyyy HH:mm"
                  :picker-options="pickerOptions"
                  @change="updateDateFilter('arrival_date_from')"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Плановая дата прибытия до">
                <el-date-picker-input
                  v-model="filter.arrival_date_to"
                  type="datetime"
                  autocomplete="false"
                  format="dd.MM.yyyy HH:mm"
                  :picker-options="pickerOptions"
                  @change="updateDateFilter('arrival_date_to')"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Дата старта аукциона от">
                <el-date-picker-input
                  v-model="filter.start_date_from"
                  type="datetime"
                  autocomplete="false"
                  format="dd.MM.yyyy HH:mm"
                  :picker-options="pickerOptions"
                  :disabled="
                    filter.category === 'drafts' ||
                    filter.category === 'cancelled'
                  "
                  @change="updateDateFilter('start_date_from')"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Дата старта аукциона до">
                <el-date-picker-input
                  v-model="filter.start_date_to"
                  type="datetime"
                  autocomplete="false"
                  format="dd.MM.yyyy HH:mm"
                  :picker-options="pickerOptions"
                  :disabled="
                    filter.category === 'drafts' ||
                    filter.category === 'cancelled'
                  "
                  @change="updateDateFilter('start_date_to')"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Дата окончания аукциона от">
                <el-date-picker-input
                  v-model="filter.stop_date_from"
                  type="datetime"
                  autocomplete="false"
                  format="dd.MM.yyyy HH:mm"
                  :picker-options="pickerOptions"
                  :disabled="
                    filter.category === 'drafts' ||
                    filter.category === 'cancelled'
                  "
                  @change="updateDateFilter('stop_date_from')"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Дата окончания аукциона до">
                <el-date-picker-input
                  v-model="filter.stop_date_to"
                  type="datetime"
                  autocomplete="false"
                  format="dd.MM.yyyy HH:mm"
                  :picker-options="pickerOptions"
                  :disabled="
                    filter.category === 'drafts' ||
                    filter.category === 'cancelled'
                  "
                  @change="updateDateFilter('stop_date_to')"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                class="form-group-el-select"
                label="Точка отправления"
              >
                <el-select
                  v-model="filter.first_point"
                  class="form-control"
                  name="first_point"
                  placeholder="Введите точку отправления"
                  clearable
                  :filterable="true"
                  :remote="true"
                  reserve-keyword
                  :loading="loading"
                  :remote-method="firstDepartmentsList"
                  @input="updateFilter('first_point')"
                  @clear="firstDepartmentsList"
                >
                  <el-option
                    v-for="item in firstDepartments"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group class="form-group-el-select" label="Конечная точка">
                <el-select
                  v-model="filter.last_point"
                  class="form-control"
                  name="last_point"
                  placeholder="Введите конечную точку"
                  clearable
                  reserve-keyword
                  :filterable="true"
                  :loading="loading"
                  :remote="true"
                  :remote-method="lastDepartmentsList"
                  @input="updateFilter('last_point')"
                  @clear="lastDepartmentsList"
                >
                  <el-option
                    v-for="item in lastDepartments"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-collapse>
    </b-card>
    <ul class="nav nav-tabs auction-filter-tabs">
      <li
        v-for="(category, index) in categories"
        :key="'category-' + index"
        class="nav-item auction-filter-tabs__item"
      >
        <input
          :id="'category-' + index"
          v-model="filter.category"
          class="auction-filter-tabs__input"
          type="radio"
          name="category"
          :value="category.value"
          @change="updateFilter('category')"
        />
        <label
          :for="'category-' + index"
          class="nav-link auction-filter-tabs__display"
          >{{ category.text }}</label
        >
      </li>
    </ul>
    <b-card no-body>
      <b-card-header>
        Аукционы
        <div class="card-header-actions">
          <small class="text-muted"
            >Дата последнего обновления {{ lastRefreshTime }}
          </small>
          <b-button
            :disabled="loading"
            variant="primary"
            size="sm"
            class="ml-1"
            @click="$refs.table.refresh()"
          >
            <i
              :class="{ 'fa-spin': loading }"
              class="fa fa-refresh"
            />&nbsp;Обновить
          </b-button>
        </div>
      </b-card-header>
      <b-card-body :class="{ loading: loading }">
        <flight-legend :transportation-types="types" />
        <v-server-table
          v-if="filter.ready"
          id="dataTable"
          ref="table"
          :columns="columns"
          :options="options"
          :theme="theme"
          @loaded="loading = false"
        >
          <span slot="routeCodes" slot-scope="props">
            <span
              v-if="
                props.row.auctionRoutes.length > 0 &&
                filter.category != 'cancelled'
              "
            >
              <span
                v-for="auctionRoute in props.row.auctionRoutes"
                :key="'auction-route-' + auctionRoute.id"
              >
                {{ auctionRoute.route.routeCode }}<br />
              </span>
            </span>
            <span v-if="filter.category == 'cancelled'">
              <span v-if="props.row.name">
                <span> {{ props.row.name }}<br /> </span>
              </span>
            </span>
            <div v-if="filter.category !== 'drafts'">
              <span v-if="props.row.firstBidWin" class="text-blue">По первой ставке</span>
              <span v-else class="text-standart">Стандартный</span>
            </div>
          </span>
          <span slot="routeWays" slot-scope="props">
            <span v-if="props.row.auctionRoutes.length > 0">
              <span
                v-for="auctionRoute in props.row.auctionRoutes"
                :key="'auction-route-' + auctionRoute.id"
              >
                {{ auctionRoute.route.routeWay.name }}<br />
              </span>
            </span>
          </span>
          <span slot="transportationNames" slot-scope="props">
            <span v-if="props.row.auctionRoutes.length > 0">
              <span
                v-for="auctionRoute in props.row.auctionRoutes"
                :key="'auction-route-' + auctionRoute.id"
              >
                <div
                  :style="`backgroundColor: ${auctionRoute.route.transportation.color};`"
                  class="flight-card"
                >
                  <span
                    :style="
                      auctionRoute.route.transportation == null
                        ? 'color: black;'
                        : 'color: white;'
                    "
                    >{{ auctionRoute.route.transportation.name }}</span
                  >
                </div>
                <br />
              </span>
            </span>
          </span>
          <span slot="transportationType" slot-scope="props">
            <span v-if="props.row.auctionRoutes.length > 0">
              <span
                v-for="auctionRoute in props.row.auctionRoutes"
                :key="'auction-route-' + auctionRoute.id"
                class="nowrap"
              >
                {{ auctionRoute.route.transportationType.name }}
                <br />
              </span>
            </span>
          </span>
          <span slot="bodyTypes" slot-scope="props">
            <span v-if="props.row.auctionRoutes.length > 0">
              <span
                v-for="auctionRoute in props.row.auctionRoutes"
                :key="'auction-route-' + auctionRoute.id"
              >
                <span
                  v-if="
                    auctionRoute.bodyTypes && auctionRoute.bodyTypes.length > 0
                  "
                >
                  <span
                    v-for="(bodyType, index) in auctionRoute.bodyTypes"
                    :key="'bodyType-' + index"
                  >
                    <span
                      v-if="bodyType.name === 'Тент с усиленной обрешеткой'"
                      v-b-tooltip.hover.bottom="'Тент с усиленной обрешеткой'"
                      class="mr-1"
                    >
                      <img
                        src="/img/icons/body-types/wood.png"
                        alt="Тент с усиленной обрешеткой"
                      />
                    </span>
                    <span
                      v-else-if="bodyType.name === 'Изотермический'"
                      v-b-tooltip.hover.bottom="'Изотермический'"
                      class="mr-1"
                    >
                      <img
                        src="/img/icons/body-types/3d.png"
                        alt="Изотермический"
                      />
                    </span>
                    <span
                      v-else-if="bodyType.name === 'Контейнер'"
                      v-b-tooltip.hover.bottom="'Контейнер'"
                      class="mr-1"
                    >
                      <img
                        src="/img/icons/body-types/container.png"
                        alt="Контейнер"
                      />
                    </span>
                    <span
                      v-else-if="bodyType.name === 'Рефрижератор'"
                      v-b-tooltip.hover.bottom="'Рефрижератор'"
                      class="mr-1"
                    >
                      <img
                        src="/img/icons/body-types/temperature-control.png"
                        alt="Рефрижератор"
                      />
                    </span>
                    <span v-else>{{ bodyType.name }}</span>
                  </span>
                </span>
                <br />
              </span>
            </span>
          </span>
          <span slot="stl" slot-scope="props">
            <span v-if="props.row.auctionRoutes.length > 0">
              <span
                v-for="auctionRoute in props.row.auctionRoutes"
                :key="'auction-route-' + auctionRoute.id"
              >
                <span v-if="auctionRoute.route.naRouteOwner" class="nowrap">{{
                  auctionRoute.route.naRouteOwner.name
                }}</span>
                <br />
              </span>
            </span>
          </span>
          <span slot="startPrice" slot-scope="props">
            <span v-if="props.row.startPrice"
              >{{ numberRUFormat(props.row.startPrice) }} руб.
              {{
                alternativeAmountValue(props.row.withNDS, props.row.startPrice)
              }}</span
            >
            <span v-else>Н/Д</span>
          </span>
          <span slot="planDatesOfFirstPointArrive" slot-scope="props">
            <span v-if="props.row.auctionRoutes.length > 0">
              <span
                v-for="auctionRoute in props.row.auctionRoutes"
                :key="'auction-route-' + auctionRoute.id"
              >
                {{
                  formatDatetimeWithOriginalTimezone(
                    auctionRoute.route.planDateOfFirstPointArrive
                  )
                }}<br />
              </span>
            </span>
          </span>
          <span slot="dimensions" slot-scope="props">
            <span v-if="props.row.auctionRoutes.length > 0">
              <span
                v-for="auctionRoute in props.row.auctionRoutes"
                :key="'auction-route-' + auctionRoute.id"
                class="nowrap"
              >
                <span
                  v-if="
                    auctionRoute.dimension ||
                    auctionRoute.optionalDimensions.length > 0
                  "
                >
                  <span v-if="props.row.status === 4 || props.row.status === 6">
                    <span v-if="props.row.bids.length > 0">
                      <span
                        v-for="(bid, index) in props.row.bids"
                        :key="'bid-' + index"
                      >
                        <span v-if="bid.winner">
                          <dimensions-view
                            :value="
                              dimensionsList(
                                auctionRoute.id,
                                auctionRoute.dimension,
                                auctionRoute.optionalDimensions
                              )
                            "
                            :price="bid.bet"
                          />
                        </span>
                      </span>
                    </span>
                    <span v-if="props.row.offers.length > 0">
                      <span
                        v-for="(offer, index) in props.row.offers"
                        :key="'offer-' + index"
                      >
                        <span v-if="offer.winner">
                          <dimensions-view
                            :value="
                              dimensionsList(
                                auctionRoute.id,
                                auctionRoute.dimension,
                                auctionRoute.optionalDimensions
                              )
                            "
                            :price="offer.bet"
                          />
                        </span>
                      </span>
                    </span>
                  </span>
                  <span v-else>
                    <dimensions-view
                      :value="
                        dimensionsList(
                          auctionRoute.id,
                          auctionRoute.dimension,
                          auctionRoute.optionalDimensions
                        )
                      "
                      :price="auctionRoute.startPrice"
                    />
                  </span>
                </span>
              </span>
            </span>
          </span>
          <span slot="startDate" slot-scope="props">{{
            formatDatetime(props.row.startDate)
          }}</span>
          <span slot="stopDate" slot-scope="props"
            >{{
              props.row.factEndDate
                ? formatDatetime(props.row.factEndDate)
                : formatDatetime(props.row.stopDate)
            }}
          </span>
          <span slot="activeBid" slot-scope="props">
            <span v-if="props.row.status === 4 || props.row.status === 6">
              <span v-if="props.row.bids.length > 0">
                <span
                  v-for="(bid, index) in props.row.bids"
                  :key="'bid-' + index"
                >
                  <span v-if="bid.winner"
                    >({{ bid.contractor.name }}) —
                    {{ numberRUFormat(bid.bet) }} руб.
                    {{
                      alternativeAmountValue(props.row.withNDS, bid.bet)
                    }}</span
                  >
                </span>
              </span>
              <span v-if="props.row.offers.length > 0">
                <span
                  v-for="(offer, index) in props.row.offers"
                  :key="'offer-' + index"
                >
                  <span v-if="offer.winner"
                    >({{ offer.contractor.name }}) —
                    {{ numberRUFormat(offer.bet) }} руб.
                    {{
                      alternativeAmountValue(props.row.withNDS, offer.bet)
                    }}</span
                  >
                </span>
              </span>
            </span>
            <span v-else>
              <span v-if="props.row.bids.length > 0">
                <span
                  v-for="(bid, index) in props.row.bids"
                  :key="'bid-' + index"
                >
                  <span v-if="bid.active"
                    >({{ bid.contractor.name }}) —
                    {{ numberRUFormat(bid.bet) }} руб.
                    {{
                      alternativeAmountValue(props.row.withNDS, bid.bet)
                    }}</span
                  >
                </span>
              </span>
              <span v-else
                >Н/Д
                <span v-if="props.row.offers.length > 0" class="text-warning"
                  >(Есть встречные)</span
                >
              </span>
            </span>
          </span>
          <span slot="savingOverrun" slot-scope="props">
            <span v-if="props.row.bids.length > 0">
              <span
                v-for="(bid, index) in props.row.bids"
                :key="'bid-' + index"
              >
                <span v-if="bid.winner">
                  <span
                    v-if="props.row.startPrice - bid.bet > 0"
                    class="text-success"
                    >{{ numberRUFormat(props.row.startPrice - bid.bet) }}
                    руб.
                    {{
                      alternativeAmountValue(
                        props.row.withNDS,
                        props.row.startPrice - bid.bet
                      )
                    }}</span
                  >
                  <span
                    v-else-if="props.row.startPrice - bid.bet < 0"
                    class="text-danger"
                    >{{ numberRUFormat(props.row.startPrice - bid.bet) }}
                    руб.
                    {{
                      alternativeAmountValue(
                        props.row.withNDS,
                        props.row.startPrice - bid.bet
                      )
                    }}</span
                  >
                  <span v-else
                    >{{ numberRUFormat(props.row.startPrice - bid.bet) }}
                    руб.
                    {{
                      alternativeAmountValue(
                        props.row.withNDS,
                        props.row.startPrice - bid.bet
                      )
                    }}</span
                  >
                </span>
              </span>
            </span>
            <span v-if="props.row.offers.length > 0">
              <span
                v-for="(offer, index) in props.row.offers"
                :key="'offer-' + index"
              >
                <span v-if="offer.winner">
                  <span
                    v-if="props.row.startPrice - offer.bet > 0"
                    class="text-success"
                    >{{ numberRUFormat(props.row.startPrice - offer.bet) }}
                    руб.
                    {{
                      alternativeAmountValue(
                        props.row.withNDS,
                        props.row.startPrice - offer.bet
                      )
                    }}</span
                  >
                  <span
                    v-else-if="props.row.startPrice - offer.bet < 0"
                    class="text-danger"
                    >{{ numberRUFormat(props.row.startPrice - offer.bet) }}
                    руб.
                    {{
                      alternativeAmountValue(
                        props.row.withNDS,
                        props.row.startPrice - offer.bet
                      )
                    }}</span
                  >
                  <span v-else
                    >{{ numberRUFormat(props.row.startPrice - offer.bet) }}
                    руб.
                    {{
                      alternativeAmountValue(
                        props.row.withNDS,
                        props.row.startPrice - offer.bet
                      )
                    }}</span
                  >
                </span>
              </span>
            </span>
          </span>
          <span slot="status" slot-scope="props">
            <span v-if="props.row.status === 1" class="text-primary"
              >Ожидает начала</span
            >
            <span v-else-if="props.row.status === 2" class="text-success"
              >Прием ставок</span
            >
            <span v-else-if="props.row.status === 3" class="text-warning"
              >Нет победителя</span
            >
            <span v-else-if="props.row.status === 4" class="text-success"
              >Есть победитель</span
            >
            <span v-else />
          </span>
          <span slot="cancelReason" slot-scope="props">
            {{ getCancelReasonText(props.row.cancelReason) }}
          </span>
          <span 
          slot="actions" 
          slot-scope="props" 
          class="d-flex flex-wrap flex-column align-items-center justify-content-center"
          >
            <a
              v-if="
                props.row.status === 0 && props.row.auctionRoutes.length > 0
              "
              v-b-tooltip.hover.bottom="'Запустить аукцион'"
              class="fa fa-mail-forward fa-lg text-success table-action-button mt-1 mb-2"
              @click="openAuctionStartModal(props.row)"
            />
            <a
              v-if="props.row.status === 1"
              v-b-tooltip.hover.bottom="'Изменить период проведения'"
              class="fa fa-history fa-lg text-warning table-action-button mb-2"
              @click="openAuctionStartModal(props.row)"
            />
            <a
              v-if="props.row.status === 3"
              v-b-tooltip.hover.bottom="'Перезапустить аукцион'"
              class="fa fa-repeat fa-lg text-warning table-action-button mb-2"
              @click="openAuctionStartModal(props.row)"
            />
            <a
              v-if="props.row.status === 0"
              v-b-tooltip.hover.bottom="'Редактировать'"
              class="fa fa-pencil fa-lg text-primary table-action-button mb-2"
              :href="'/auctions/customer/auction/id' + props.row.id"
            />
            <a
              v-if="
                props.row.status !== 0 &&
                props.row.status !== 5 &&
                props.row.status !== 6
              "
              v-b-tooltip.hover.bottom="'Вернуть в черновик'"
              class="fa fa-reply fa-lg text-primary table-action-button mb-2"
              @click="onDraft(props.row.id)"
            />
            <a
              v-b-tooltip.hover.bottom="'Просмотреть'"
              class="fa fa-eye fa-lg text-secondary table-action-button mb-2"
              :href="
                '/customers/id' +
                $store.state.user.ownerId +
                '/auction/id' +
                props.row.id
              "
            />
            <a
            v-if="props.row.commentInfo"
            v-b-tooltip.hover.bottom="'Прочитать комментарий'"
            class="fa fa-exclamation fa-lg text-danger table-action-button mb-2"
            @click="showCommentModal(props.row)"
            />
            <a
              v-if="props.row.status !== 5 && props.row.status !== 6"
              v-b-tooltip.hover.bottom="'Удалить аукцион'"
              class="fa fa-close fa-lg text-danger table-action-button"
              @click="confirmDeleteAuction(props.row.id)"
            />
          </span>
        </v-server-table>
      </b-card-body>
    </b-card>

    <b-modal
    v-model="showComment"
    :title="'Комментарии к аукциону ' + auctionName"
    class="modal-warning"
    cancel-title="Назад"
    ok-only
    >
    <div 
    v-for="(auctionRoute, index) in this.auctionRoutes"
    :key = "'index' + index"
    > 
    <span class="font-weight-bold">{{auctionRoute.routeWay.code}}:</span>
    {{auctionRoute.comment.comment}}
    </div>
    </b-modal>

    <b-modal
      v-model="startAuctionModal.show"
      :title="'Запуск аукциона ' + routeCodes"
      class="modal-warning"
      cancel-title="Назад"
      ok-variant="success"
      :ok-disabled="startAuctionDisabled"
      ok-title="Запустить аукцион"
      @ok="onStart()"
    >
      <p>Выберите тип запуска аукциона и назначьте время</p>
      <b-form-group class="form-group-el-select" label="Способ запуска">
        <el-select
          v-model="startAuctionModal.method.value"
          class="form-control"
          name="route-way"
          :loading="loading"
          @change="startAuctionAutoDateSet()"
        >
          <el-option
            v-for="item in startAuctionModal.method.options"
            :key="item.value"
            :label="item.text"
            :value="item.value"
          />
        </el-select>
      </b-form-group>
      <b-form-group
        v-if="startAuctionModal.method.value"
        label="Старт аукциона"
        label-for="start-auction-modal-start-date"
      >
        <el-date-picker-input
          id="start-auction-modal-start-date"
          v-model="startAuctionModal.startDate"
          type="datetime"
          placeholder=""
          format="dd.MM.yyyy HH:mm"
          :picker-options="pickerOptions"
          @change="startAuctionStartDateChange()"
        />
      </b-form-group>
      <b-form-group
        label="Окончание приема ставок"
        label-for="start-auction-modal-end-date"
      >
        <el-date-picker-input
          id="start-auction-modal-end-date"
          v-model="startAuctionModal.stopDate"
          type="datetime"
          placeholder=""
          format="dd.MM.yyyy HH:mm"
          :picker-options="pickerOptions"
        />
      </b-form-group>
      <b-form-group>
        <b-form-radio-group stacked v-model="startAuctionModal.selected">
          <b-form-radio
            class="mb-3"
            value="standartAuction"
            id="standartAuction"
            name="standart-auction"
            @change="startAuctionAutoDateSet()"
            >Стандартный запуск аукциона</b-form-radio
          >
          <b-form-radio
            class="mb-3"
            value="firstBidWin"
            id="firstBidWin"
            name="first-bid-win"
            @change="startAuctionAutoDateSet()"
            >Розыгрыш победителем по первой ставке</b-form-radio
          >
          <b-form-radio
            value="enableOffers"
            id="enableOffers"
            name="enable-offers"
            @change="startAuctionAutoDateSet()"
            >Возможность подачи встречных предложений</b-form-radio
          >
        </b-form-radio-group>
      </b-form-group>

      <b-form-group v-if="startAuctionModal.selected === 'enableOffers'">
        <el-date-picker-input
          v-model="startAuctionModal.offerDate"
          type="datetime"
          format="dd.MM.yyyy HH:mm"
          placeholder="Дата и время начала подачи встречных предложений"
          :picker-options="pickerOptions"
        />
        <small class="form-text text-muted"
          >Оставьте поле пустым если хотите получать втречные предложения на
          протяжении всего аукциона</small
        >
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import DimensionsView from "../../components/DimensionsView.vue";
import ElDatePickerInput from "../../components/ElDatePickerInput.vue";
import ElSelectClearable from "../../components/ElSelectClearable.vue";
import Vue from "vue";
import { ServerTable, Event } from "vue-tables-2";
import notifications from "../../components/mixins/notifications";
import {
  customersAuctionsList,
  customersAuctionClearRun,
  customersAuctionChangeDates,
  customersAuctionCancel,
  customersAuctionDraft,
  customersAuctionsRoutesList,
  customersAuctionsContractorsList,
  routesTransportationTypesList,
  routeOwnersList,
  departmentsList,
} from "../../services/api";
import {
  valueToValueWithVAT,
  valueToValueWithoutVAT,
  numberToRUFormat,
} from "@/components/mixins/helpers";
import moment from "moment";
import tz from "moment-timezone";
import { arrayStringToNumber } from "../../components/mixins/helpers";
import { queryToUrl, URLToQuery } from "../../services/http";
import { auctionCancelReason } from "../../components/mixins/auctionCancelReason";
import FlightLegend from "../../components/FlightLegend.vue";

Vue.use(ServerTable);

export default {
  name: "CustomerAuctions",
  components: {
    ServerTable,
    Event,
    FlightLegend,
    ElSelectClearable,
    ElDatePickerInput,
    DimensionsView,
  },
  mixins: [notifications, auctionCancelReason],
  data: function () {
    return {
      lastRefreshTime: null,
      auctionsId: null,
      auctionsStatus: null,
      routeCodes: null,
      showComment: null,
      categories: [
        {
          value: "drafts",
          text: "Черновики",
        },
        {
          value: "active",
          text: "Активные",
        },
        {
          value: "completed",
          text: "Завершенные",
        },
        {
          value: "graduated",
          text: "Утвержденные",
        },
        {
          value: "cancelled",
          text: "Отмененные",
        },
      ],
      routeIds: [],
      types: [],
      contractors: [],
      statuses: [],
      managers: [],
      auctionRoutes: null,
      auctionName: null,
      firstDepartments: [],
      lastDepartments: [],
      filter: {
        category: "drafts",
        route_id: [],
        transportation_id: null,
        arrival_date_from: null,
        arrival_date_to: null,
        start_date_from: null,
        start_date_to: null,
        stop_date_from: null,
        stop_date_to: null,
        contractor: [],
        status: [],
        stl_id: [],
        first_point: [],
        last_point: [],
        page: 1,
        limit: 100,
        ready: false,
      },
      loading: false,
      columns: [
        "routeCodes",
        "routeWays",
        "transportationNames",
        "transportationType",
        "bodyTypes",
        "stl",
        "startPrice",
        "planDatesOfFirstPointArrive",
        "dimensions",
        "actions",
      ],
      options: {
        customFilters: [
          "category",
          "route_id",
          "first_point",
          "last_point",
          "transportation_id",
          "arrival_date_from",
          "arrival_date_to",
          "start_date_from",
          "start_date_to",
          "stop_date_from",
          "stop_date_to",
          "contractor",
          "status",
          "stl_id",
        ],
        requestFunction: (data) => {
          this.loading = true;
          this.filter.page = data.page;
          this.filter.limit = data.limit;
          this.customersAuctionsRoutesList();
          this.customersAuctionsContractorsList();
          this.lastRefreshTime = moment().format("DD.MM.YYYY HH:mm:ss");
          return customersAuctionsList(
            this.$store.state.user.ownerId,
            Object.assign(data, this.updateRouteParams())
          );
        },
        responseAdapter({ data }) {
          data.items.forEach((item) => {
            if (item.auctionRoutes) {
              for (let auctionRoute in item.auctionRoutes) {
                if (item.auctionRoutes[auctionRoute].route.comment && 
                (item.auctionRoutes[auctionRoute].route.comment.comment !== '' ||
                item.auctionRoutes[auctionRoute].route.comment.comment !== undefined)) {
                  item.commentInfo = true;
                }
            };
            }
            if (item.bids.length > 0) {
              item.bids = item.bids.filter((x) => x.round === item.round);
            }
            if (item.offers.length > 0) {
              item.offers = item.offers.filter((x) => x.round === item.round);
            }
          });
          return {
            data: data.items,
            count: data.totalCount,
          };
        },
        headings: {
          routeCodes: "Коды рейсов",
          routeWays: "Маршруты рейсов",
          transportationNames: "Типы перевозок",
          transportationType: "Виды перевозок",
          bodyTypes: "Разре-шенные типы кузова",
          stl: "Группа ответственных",
          startPrice: "Начальная цена",
          planDatesOfFirstPointArrive: "Плановые даты прибытия",
          startDate: "Старт аукциона",
          stopDate: "Окончание аукциона",
          activeBid: "Перевозчик и актуальная ставка",
          savingOverrun: "Экономия/Перерасход",
          cancelReason: "Причина отмены",
          dimensions: "Габариты",
          status: "Статус",
          actions: "",
        },
        sortable: [],
        filterable: [],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort",
        },
        pagination: {
          chunk: 10,
          edge: true,
          nav: "fixed",
        },
        perPage: 100,
        texts: {
          count:
            "Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись",
          first: "Первая",
          last: "Последняя",
          filter: "Фильтр:",
          filterPlaceholder: "Поисковый запрос",
          limit: "Записи:",
          page: "Страница:",
          noResults: "Нет совпадающих записей",
          filterBy: "Фильтр по {column}",
          loading: "Загрузка...",
          defaultOption: "Выбор {column}",
          columns: "Столбцы",
        },
      },
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      startAuctionModal: {
        show: false,
        selected: "standartAuction",
        method: {
          options: [
            { value: 0, text: "Запустить сейчас" },
            { value: 1, text: "Запустить как отложенный" },
          ],
          value: 0,
        },
        startDate: "",
        stopDate: "",
        offerDate: "",
      },
      showComment: false,
      useVuex: false,
      theme: "bootstrap4",
      template: "default",
    };
  },
  computed: {
    dimensionsList(auctionRouteId, dimension, optionalDimensions) {
      return (auctionRouteId, dimension, optionalDimensions) => {
        return {
          id: auctionRouteId,
          ...(dimension && { dimension: dimension }),
          ...(optionalDimensions?.length > 0 && {
            optionalDimensions: optionalDimensions,
          }),
        };
      };
    },
    startAuctionDisabled() {
      if (this.startAuctionModal.method.value) {
        return !(
          this.startAuctionModal.startDate && this.startAuctionModal.stopDate
        );
      } else {
        return !this.startAuctionModal.stopDate;
      }
    },
    formStrStart() {
      const calculationFormData = {};
      if (
        this.startAuctionModal.method.value &&
        this.startAuctionModal.startDate
      ) {
        calculationFormData.startDate = moment(
          this.startAuctionModal.startDate
        ).format();
      }
      if (this.startAuctionModal.stopDate) {
        calculationFormData.stopDate = moment(
          this.startAuctionModal.stopDate
        ).format();
      }
      if (this.startAuctionModal.selected === "firstBidWin") {
        calculationFormData.first_bid_win =
        this.startAuctionModal.selected === "firstBidWin";
      }
      if (this.startAuctionModal.selected === "enableOffers") {
        calculationFormData.enable_offers =
        this.startAuctionModal.selected === "enableOffers";
      }
      if (this.startAuctionModal.offerDate) {
        calculationFormData.offerDate = moment(
          this.startAuctionModal.offerDate
        ).format();
      }
      return calculationFormData;
    },
    formStrSnakeStart() {
      const calculationFormData = {};
      if (
        this.startAuctionModal.method.value &&
        this.startAuctionModal.startDate
      ) {
        calculationFormData.start_date = moment(
          this.startAuctionModal.startDate
        ).format();
      }
      if (this.startAuctionModal.stopDate) {
        calculationFormData.stop_date = moment(
          this.startAuctionModal.stopDate
        ).format();
      }
      if (this.startAuctionModal.selected === "firstBidWin") {
        calculationFormData.first_bid_win =
        this.startAuctionModal.selected === "firstBidWin";
      }
      if (this.startAuctionModal.selected === "enableOffers") {
        calculationFormData.enable_offers =
        this.startAuctionModal.selected === "enableOffers";
      }
      if (this.startAuctionModal.offerDate) {
        calculationFormData.offer_date = moment(
          this.startAuctionModal.offerDate
        ).format();
      }
      return calculationFormData;
    },
  },
  created() {
    this.initRouteParams();
  },
  mounted() {
    this.routesTransportationTypesList();
    this.customersRoutesManagersList();
    this.firstDepartmentsList();
    this.lastDepartmentsList();
    const queries = this.$router.currentRoute.query;
    /**
     * Если в URL есть параметр 'limit'
     * то перекидываем таблицу на соответствующее
     * параметру значение
     * */
    if (queries.limit) {
      this.$refs.table.setLimit(queries.limit);
    }
    /**
     * Если в URL есть параметр 'page'
     * то перекидываем таблицу на соответствующее
     * параметру значение
     * */
    if (queries.page) {
      this.$refs.table.setPage(queries.page);
    }
    /**
     * Записываем параметр при
     * изменении страницы таблицы
     * и записываем в URL
     * */
    const that = this;
    Event.$on("vue-tables.pagination", (data) => {
      that.filter.page = data;
      that.updateRouteParams();
    });
    Event.$on("vue-tables.limit", (data) => {
      this.filter.page = 1;
      this.filter.limit = data;
      this.updateRouteParams();
    });
  },
  methods: {
    alternativeAmountValue(vat, value) {
      if (value) {
        if (!vat) {
          return (
            "(" +
            this.numberRUFormat(valueToValueWithVAT(value)) +
            " руб. С НДС)"
          );
        } else {
          return (
            "(" +
            this.numberRUFormat(valueToValueWithoutVAT(value)) +
            " руб. Без НДС)"
          );
        }
      } else {
        return "";
      }
    },
    numberRUFormat(value) {
      return numberToRUFormat(value);
    },
    showCommentModal(auction) {
      this.showComment = true;
      this.auctionsId = auction.id;
      this.auctionRoutes = [];
      for (let auctionRoute in auction.auctionRoutes) {
        this.auctionRoutes.push(auction.auctionRoutes[auctionRoute].route);
      };
      this.auctionName = auction.name;
    },
    openAuctionStartModal(auction) {
      this.startAuctionModal.show = true;
      this.auctionsId = auction.id;
      this.auctionsStatus = auction.status;
      this.routeCodes = auction.auctionRoutes
        .map((auctionRoute) => {
          return auctionRoute.route.routeCode;
        })
        .join(";");
        if(auction.firstBidWin) {
          this.startAuctionModal.selected === "firstBidWin";
        } else if (auction.enableOffers) {
          this.startAuctionModal.selected === "enableOffers";
        } else {
          this.startAuctionModal.selected === "standartAuction";
        }
			if (auction.offerDate) {
        this.startAuctionModal.offerDate = moment(auction.offerDate).format();
      }
      this.startAuctionAutoDateSet();
    },
    startAuctionAutoDateSet() {
      if (this.startAuctionModal.selected === "enableOffers") {
        if (!this.startAuctionModal.method.value) {
          this.startAuctionModal.stopDate = moment().add(60, "m").format();
        } else {
          this.startAuctionModal.stopDate = moment(
            this.startAuctionModal.startDate
          )
            .add(60, "m")
            .format();
        }
      } else {
        if (!this.startAuctionModal.method.value) {
          this.startAuctionModal.stopDate = moment().add(30, "m").format();
        } else {
          this.startAuctionModal.stopDate = moment(
            this.startAuctionModal.startDate
          )
            .add(30, "m")
            .format();
        }
      }
    },
    startAuctionStartDateChange() {
      if (this.startAuctionModal.startDate) {
        this.startAuctionAutoDateSet();
      }
    },
    async customersAuctionsRoutesList(name) {
      this.loading = true;
      const params = { limit: 100 };
      if (this.filter.category === "drafts") {
        [0].map((item, index) => {
          params["status[" + index + "]"] = item;
        });
      } else if (this.filter.category === "active") {
        [1, 2].map((item, index) => {
          params["status[" + index + "]"] = item;
        });
      } else if (this.filter.category === "completed") {
        [3, 4].map((item, index) => {
          params["status[" + index + "]"] = item;
        });
      } else if (this.filter.category === "graduated") {
        [6].map((item, index) => {
          params["status[" + index + "]"] = item;
        });
      } else if (this.filter.category === "cancelled") {
        [5].map((item, index) => {
          params["status[" + index + "]"] = item;
        });
      }
      if (name) {
        params.query = name;
      }
      const response = await customersAuctionsRoutesList(
        this.$store.state.user.ownerId,
        params
      );
      if (response && response.status === 200) {
        this.routeIds = response.data.items.map((item) => {
          return { value: item.id, text: item.routeCode };
        });
      }
      if (this.filter.route_id.length > 0) {
        this.customersSelectedRoutesList();
      }
      this.loading = false;
    },
    async customersSelectedRoutesList() {
      this.loading = true;
      const selectedRouteWays = {};
      this.filter.route_id.map((item, index) => {
        selectedRouteWays["id[" + index + "]"] = item;
      });
      const response = await customersAuctionsRoutesList(
        this.$store.state.user.ownerId,
        selectedRouteWays
      );
      if (response && response.status === 200) {
        response.data.items.forEach((item) => {
          this.routeIds = this.routeIds.filter((x) => x.value !== item.id);
          this.routeIds.unshift({
            value: item.id,
            text: item.routeCode,
          });
        });
      }
      this.loading = false;
    },
    async routesTransportationTypesList() {
      this.loading = true;
      const response = await routesTransportationTypesList({
        limit: 100,
      });
      if (response && response.status === 200) {
        this.types = response.data.items.map((item) => {
          return {
            value: item.id,
            text: item.name,
            color: item.color,
            description: item.description,
          };
        });
        this.types.unshift({ value: null, text: "Все" });
      }
      this.loading = false;
    },
    async firstDepartmentsList(name) {
      this.loading = true;
      const params = { limit: 100 };
      if (name) {
        params.name = name;
      }
      const response = await departmentsList(params);
      if (response && response.status === 200) {
        this.firstDepartments = response.data.items.map((item) => {
          return {
            value: item.id,
            text: item.code,
          };
        });
      }
      this.loading = false;
    },
    async lastDepartmentsList(name) {
      this.loading = true;
      const params = { limit: 100 };
      if (name) {
        params.name = name;
      }
      const response = await departmentsList(params);
      if (response && response.status === 200) {
        this.lastDepartments = response.data.items.map((item) => {
          return {
            value: item.id,
            text: item.code,
          };
        });
      }
      this.loading = false;
    },
    async onDraft(auctionsId) {
      this.loading = true;
      const response = await customersAuctionDraft(
        this.$store.state.user.ownerId,
        auctionsId
      );
      if (response && response.status === 200) {
        this.showSuccess("Аукцион переведен в черновик");
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
    confirmDeleteAuction(auctionsId) {
      this.$bvModal
        .msgBoxConfirm("Вы уверены что хотите удалить аукцион?", {
          okTitle: "Да",
          cancelTitle: "Назад",
        })
        .then((confirm) => {
          if (confirm) {
            this.deleteAuction(auctionsId);
          }
        });
    },
    async deleteAuction(auctionsId) {
      this.loading = true;
      const response = await customersAuctionCancel(
        this.$store.state.user.ownerId,
        auctionsId
      );
      if (response && response.status === 200) {
        this.showSuccess("Аукцион удален");
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
    formatDatetimeWithOriginalTimezone(date) {
      const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return date
        ? moment(date, "YYYY-MM-DDTHH:mm")
            .tz(clientTimezone)
            .format("DD.MM.YYYY HH:mm")
        : "";
    },
    formatDatetime(date) {
      return date ? moment(date).format("DD.MM.YYYY HH:mm") : "";
    },
    updateFilter(field) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        const val = this.filter[field] !== "" ? this.filter[field] : null;
        if (field === "category") {
          this.filter.status = [];
          if (this.filter.category === "drafts") {
            this.statuses = [
              {
                value: 0,
                text: "Черновик",
              },
            ];
            this.columns = [
              "routeCodes",
              "routeWays",
              "transportationNames",
              "transportationType",
              "bodyTypes",
              "stl",
              "startPrice",
              "planDatesOfFirstPointArrive",
              "dimensions",
              "actions",
            ];
          } else if (this.filter.category === "active") {
            this.statuses = [
              {
                value: 1,
                text: "Ожидает начала",
              },
              {
                value: 2,
                text: "Прием ставок",
              },
            ];
            this.columns = [
              "routeCodes",
              "routeWays",
              "transportationNames",
              "transportationType",
              "bodyTypes",
              "stl",
              "startPrice",
              "planDatesOfFirstPointArrive",
              "startDate",
              "stopDate",
              "activeBid",
              "dimensions",
              "status",
              "actions",
            ];
            this.options.headings.activeBid = "Перевозчик и актуальная ставка";
          } else if (this.filter.category === "completed") {
            this.statuses = [
              {
                value: 3,
                text: "Нет победителя",
              },
              {
                value: 4,
                text: "Есть победитель",
              },
            ];
            this.columns = [
              "routeCodes",
              "routeWays",
              "transportationNames",
              "transportationType",
              "bodyTypes",
              "stl",
              "startPrice",
              "planDatesOfFirstPointArrive",
              "startDate",
              "stopDate",
              "activeBid",
              "dimensions",
              "status",
              "actions",
            ];
            this.options.headings.activeBid = "Победитель и финальная ставка";
          } else if (this.filter.category === "graduated") {
            this.statuses = [
              {
                value: 6,
                text: "Утвержден",
              },
            ];
            this.columns = [
              "routeCodes",
              "routeWays",
              "transportationNames",
              "transportationType",
              "bodyTypes",
              "stl",
              "startPrice",
              "planDatesOfFirstPointArrive",
              "startDate",
              "stopDate",
              "activeBid",
              "savingOverrun",
              "dimensions",
              "actions",
            ];
            this.options.headings.activeBid = "Победитель и финальная ставка";
          } else if (this.filter.category === "cancelled") {
            this.statuses = [
              {
                value: 5,
                text: "Отменен",
              },
            ];
            this.columns = [
              "routeCodes",
              "startDate",
              "stopDate",
              "cancelReason",
              "dimensions",
              "actions",
            ];
          }
        }
        Event.$emit("vue-tables.filter::" + field, val);
      }, 800);
    },
    updateDateFilter(field) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        const val = this.filter[field]
          ? moment(this.filter[field]).format("YYYY-MM-DDTHH:mm")
          : null;
        Event.$emit("vue-tables.filter::" + field, val);
      }, 800);
    },
    updateRouteParams() {
      const res = {};
      if (this.filter.category) {
        res.category = this.filter.category;
      }
      if (this.filter.route_id.length) {
        res.route_id = this.filter.route_id;
      }
      if (this.filter.transportation_id) {
        res.transportation_id = this.filter.transportation_id;
      }
      if (this.filter.stl_id.length) {
        res.stl_id = this.filter.stl_id;
      }
      if (this.filter.arrival_date_from) {
        res.arrival_date_from = moment(this.filter.arrival_date_from).format(
          "YYYY-MM-DDTHH:mm"
        );
      }
      if (this.filter.arrival_date_to) {
        res.arrival_date_to = moment(this.filter.arrival_date_to).format(
          "YYYY-MM-DDTHH:mm"
        );
      }
      if (this.filter.start_date_from) {
        res.start_date_from = moment(this.filter.start_date_from)
          .utc()
          .format("YYYY-MM-DDTHH:mm");
      }
      if (this.filter.start_date_to) {
        res.start_date_to = moment(this.filter.start_date_to)
          .utc()
          .format("YYYY-MM-DDTHH:mm");
      }
      if (this.filter.stop_date_from) {
        res.stop_date_from = moment(this.filter.stop_date_from)
          .utc()
          .format("YYYY-MM-DDTHH:mm");
      }
      if (this.filter.stop_date_to) {
        res.stop_date_to = moment(this.filter.stop_date_to)
          .utc()
          .format("YYYY-MM-DDTHH:mm");
      }
      if (this.filter.contractor.length) {
        res.contractor = this.filter.contractor;
      }
      if (this.filter.status && this.filter.status.length) {
        res.status = this.filter.status;
      } else {
        if (this.filter.category === "drafts") {
          res.status = [0];
        } else if (this.filter.category === "active") {
          res.status = [1, 2];
        } else if (this.filter.category === "completed") {
          res.status = [3, 4];
        } else if (this.filter.category === "graduated") {
          res.status = [6];
        } else if (this.filter.category === "cancelled") {
          res.status = [5];
        }
        res.category = this.filter.category;
      }
      const storedFilter = {...this.filter};
      this.$store.commit('setFilterParams', {componentName: this.$options.name, params: storedFilter});
      if (this.filter.page && this.filter.page !== 1) {
        res.page = this.filter.page;
      } else {
        delete res.page;
      }
      history.pushState({}, document.title, this.$router.currentRoute.path);
      if (Object.keys(res).length !== 0) {
        const url = queryToUrl('', res);
        history.pushState(null, null, url);
        this.customersAuctionsRoutesList();
      }
      return res;
    },
    /**
     * Запись параметров из URL в this.filter
     * */
    initRouteParams() {
      const params = URLToQuery(this.$route.fullPath);
      const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (Object.keys(params).length !== 0) {
        if (params.hasOwnProperty('transportation_id')) {
          params.transportation_id = Number(params.transportation_id);
        }
        if (params.hasOwnProperty("route_id")) {
          params.route_id = arrayStringToNumber(params.route_id);
        }
        if (params.hasOwnProperty("stl_id")) {
          params.stl_id = arrayStringToNumber(params.stl_id);
        }
        if (params.hasOwnProperty("start_date_from")) {
          params.start_date_from = moment
            .utc(params.start_date_from)
            .tz(clientTimezone)
            .format("YYYY-MM-DDTHH:mm");
        }
        if (params.hasOwnProperty("start_date_to")) {
          params.start_date_to = moment
            .utc(params.start_date_to)
            .tz(clientTimezone)
            .format("YYYY-MM-DDTHH:mm");
        }
        if (params.hasOwnProperty("stop_date_from")) {
          params.stop_date_from = moment
            .utc(params.stop_date_from)
            .tz(clientTimezone)
            .format("YYYY-MM-DDTHH:mm");
        }
        if (params.hasOwnProperty("stop_date_to")) {
          params.stop_date_to = moment
            .utc(params.stop_date_to)
            .tz(clientTimezone)
            .format("YYYY-MM-DDTHH:mm");
        }
        if (params.hasOwnProperty("status")) {
          if (
            (params.status.indexOf("1") !== -1) ^
              (params.status.indexOf("2") !== -1) ||
            (params.status.indexOf("3") !== -1) ^
              (params.status.indexOf("4") !== -1)
          ) {
            params.status = arrayStringToNumber(params.status);
          } else {
            delete params.status;
          }
        }
        if (params.hasOwnProperty("category")) {
          if (params.category === "drafts") {
            this.statuses = [
              {
                value: 0,
                text: "Черновик",
              },
            ];
            this.columns = [
              "routeCodes",
              "routeWays",
              "transportationNames",
              "transportationType",
              "bodyTypes",
              "stl",
              "startPrice",
              "planDatesOfFirstPointArrive",
              "dimensions",
              "actions",
            ];
          } else if (params.category === "active") {
            this.statuses = [
              {
                value: 1,
                text: "Ожидает начала",
              },
              {
                value: 2,
                text: "Прием ставок",
              },
            ];
            this.columns = [
              "routeCodes",
              "routeWays",
              "transportationNames",
              "transportationType",
              "bodyTypes",
              "stl",
              "startPrice",
              "planDatesOfFirstPointArrive",
              "startDate",
              "stopDate",
              "activeBid",
              "dimensions",
              "status",
              "actions",
            ];
            this.options.headings.activeBid = "Перевозчик и актуальная ставка";
          } else if (params.category === "completed") {
            this.statuses = [
              {
                value: 3,
                text: "Нет победителя",
              },
              {
                value: 4,
                text: "Есть победитель",
              },
            ];
            this.columns = [
              "routeCodes",
              "routeWays",
              "transportationNames",
              "transportationType",
              "bodyTypes",
              "stl",
              "startPrice",
              "planDatesOfFirstPointArrive",
              "startDate",
              "stopDate",
              "activeBid",
              "dimensions",
              "status",
              "actions",
            ];
            this.options.headings.activeBid = "Победитель и финальная ставка";
          } else if (params.category === "graduated") {
            this.statuses = [
              {
                value: 6,
                text: "Утвержден",
              },
            ];
            this.columns = [
              "routeCodes",
              "routeWays",
              "transportationNames",
              "transportationType",
              "bodyTypes",
              "stl",
              "startPrice",
              "planDatesOfFirstPointArrive",
              "startDate",
              "stopDate",
              "activeBid",
              "savingOverrun",
              "dimensions",
              "actions",
            ];
            this.options.headings.activeBid = "Победитель и финальная ставка";
          } else if (params.category === "cancelled") {
            this.statuses = [
              {
                value: 5,
                text: "Отменен",
              },
            ];
            this.columns = [
              "routeCodes",
              "startDate",
              "stopDate",
              "cancelReason",
              "dimensions",
              "actions",
            ];
          }
        }
        if (params.hasOwnProperty("contractor")) {
          params.contractor = arrayStringToNumber(params.contractor);
        }
        if (params.hasOwnProperty("page")) {
          params.page = Number(params.page);
        }
        this.filter.ready = false;
        this.$nextTick(() => {
          Object.assign(this.filter, params);
          this.filter.ready = true;
        });
      } else {
        const params = this.$store.getters.getFilterParams(this.$options.name);
        this.filter.ready = false;
        if (params) {
          this.$nextTick(() => {
            if (params.hasOwnProperty("route_id")) {
              params.route_id = arrayStringToNumber(params.route_id);
            }
            if (params.hasOwnProperty("stl_id")) {
              params.stl_id = arrayStringToNumber(params.stl_id);
            }
            if (params.hasOwnProperty("status")) {
              if (
                (params.status.indexOf("1") !== -1) ^
                  (params.status.indexOf("2") !== -1) ||
                (params.status.indexOf("3") !== -1) ^
                  (params.status.indexOf("4") !== -1)
              ) {
                params.status = arrayStringToNumber(params.status);
              } else {
                delete params.status;
              }
            }
            if (params.hasOwnProperty("category")) {
              if (params.category === "drafts") {
                this.statuses = [
                  {
                    value: 0,
                    text: "Черновик",
                  },
                ];
                this.columns = [
                  "routeCodes",
                  "routeWays",
                  "transportationNames",
                  "transportationType",
                  "bodyTypes",
                  "stl",
                  "startPrice",
                  "planDatesOfFirstPointArrive",
                  "dimensions",
                  "actions",
                ];
              } else if (params.category === "active") {
                this.statuses = [
                  {
                    value: 1,
                    text: "Ожидает начала",
                  },
                  {
                    value: 2,
                    text: "Прием ставок",
                  },
                ];
                this.columns = [
                  "routeCodes",
                  "routeWays",
                  "transportationNames",
                  "transportationType",
                  "bodyTypes",
                  "stl",
                  "startPrice",
                  "planDatesOfFirstPointArrive",
                  "startDate",
                  "stopDate",
                  "activeBid",
                  "dimensions",
                  "status",
                  "actions",
                ];
                this.options.headings.activeBid =
                  "Перевозчик и актуальная ставка";
              } else if (params.category === "completed") {
                this.statuses = [
                  {
                    value: 3,
                    text: "Нет победителя",
                  },
                  {
                    value: 4,
                    text: "Есть победитель",
                  },
                ];
                this.columns = [
                  "routeCodes",
                  "routeWays",
                  "transportationNames",
                  "transportationType",
                  "bodyTypes",
                  "stl",
                  "startPrice",
                  "planDatesOfFirstPointArrive",
                  "startDate",
                  "stopDate",
                  "activeBid",
                  "dimensions",
                  "status",
                  "actions",
                ];
                this.options.headings.activeBid =
                  "Победитель и финальная ставка";
              } else if (params.category === "graduated") {
                this.statuses = [
                  {
                    value: 6,
                    text: "Утвержден",
                  },
                ];
                this.columns = [
                  "routeCodes",
                  "routeWays",
                  "transportationNames",
                  "transportationType",
                  "bodyTypes",
                  "stl",
                  "startPrice",
                  "planDatesOfFirstPointArrive",
                  "startDate",
                  "stopDate",
                  "activeBid",
                  "savingOverrun",
                  "dimensions",
                  "actions",
                ];
                this.options.headings.activeBid =
                  "Победитель и финальная ставка";
              } else if (params.category === "cancelled") {
                this.statuses = [
                  {
                    value: 5,
                    text: "Отменен",
                  },
                ];
                this.columns = [
                  "routeCodes",
                  "startDate",
                  "stopDate",
                  "cancelReason",
                  "dimensions",
                  "actions",
                ];
              }
            }
            if (params.hasOwnProperty("contractor")) {
              params.contractor = arrayStringToNumber(params.contractor);
            }
            if (params.hasOwnProperty("page")) {
              params.page = Number(params.page);
            }
            Object.assign(this.filter, params);
            this.filter.ready = true;
          });
        } else {
          this.filter.ready = true;
        }
      }
    },
    async onStart() {
      this.loading = true;
      if (this.auctionsStatus === 0) {
        const response = await customersAuctionClearRun(
          this.$store.state.user.ownerId,
          this.auctionsId,
          this.formStrSnakeStart
        );
        if (response && response.status === 200) {
          this.showSuccess("Аукцион подготовлен к запуску");
          this.$refs.table.refresh();
        }
      } else if (this.auctionsStatus === 1) {
        const response = await customersAuctionChangeDates(
          this.$store.state.user.ownerId,
          this.auctionsId,
          this.formStrSnakeStart
        );
        if (response && response.status === 200) {
          this.showSuccess("Даты запуска аукциона успешно обновлены");
          this.$refs.table.refresh();
        }
      } else if (this.auctionsStatus === 3) {
        const response = await customersAuctionDraft(
          this.$store.state.user.ownerId,
          this.auctionsId
        );
        if (response && response.status === 200) {
          this.loading = true;
          const response = await customersAuctionClearRun(
            this.$store.state.user.ownerId,
            this.auctionsId,
            this.formStrSnakeStart
          );
          if (response && response.status === 200) {
            this.showSuccess("Аукцион подготовлен к запуску");
            this.$refs.table.refresh();
          }
          this.loading = false;
        }
      }
      this.loading = false;
    },
    async customersAuctionsContractorsList(query) {
      this.loading = true;
      const params = { limit: 100 };
      if (this.filter.category === "drafts") {
        [0].map((item, index) => {
          params["status[" + index + "]"] = item;
        });
      } else if (this.filter.category === "active") {
        [1, 2].map((item, index) => {
          params["status[" + index + "]"] = item;
        });
      } else if (this.filter.category === "completed") {
        [3, 4].map((item, index) => {
          params["status[" + index + "]"] = item;
        });
      } else if (this.filter.category === "graduated") {
        [6].map((item, index) => {
          params["status[" + index + "]"] = item;
        });
      } else if (this.filter.category === "cancelled") {
        [5].map((item, index) => {
          params["status[" + index + "]"] = item;
        });
      }
      if (query) {
        params.query = query;
      }
      const response = await customersAuctionsContractorsList(
        this.$store.state.user.ownerId,
        params
      );
      if (response && response.status === 200) {
        this.contractors = response.data.items.map((item) => {
          return { value: item.id, text: item.name };
        });
      }
      if (this.filter.contractor.length > 0) {
        this.customersRoutesSelectedContractorsList();
      }
      this.loading = false;
    },
    async customersRoutesSelectedContractorsList() {
      this.loading = true;
      const selectedContractors = {};
      this.filter.contractor.map((item, index) => {
        selectedContractors["id[" + index + "]"] = item;
      });
      const response = await customersAuctionsContractorsList(
        this.$store.state.user.ownerId,
        selectedContractors
      );
      if (response && response.status === 200) {
        response.data.items.forEach((item) => {
          this.contractors = this.contractors.filter(
            (x) => x.value !== item.id
          );
          this.contractors.unshift({
            value: item.id,
            text: item.name,
          });
        });
      }
      this.loading = false;
    },
    async customersRoutesManagersList(query) {
      this.loading = true;
      const params = { limit: 100 };
      if (query) {
        params.query = query;
      }
      const response = await routeOwnersList(params);
      if (response && response.status === 200) {
        this.managers = response.data.items.map((item) => {
          return {
            value: item.id,
            text: item.name,
          };
        });
      }
      if (this.filter.stl_id.length > 0) {
        await this.customersRoutesSelectedManagersList();
      }
      this.loading = false;
    },
    async customersRoutesSelectedManagersList() {
      this.loading = true;
      const selectedManagers = {};
      this.filter.stl_id.map((item, index) => {
        selectedManagers["id[" + index + "]"] = item;
      });
      const response = await routeOwnersList(selectedManagers);
      if (response && response.status === 200) {
        response.data.items.forEach((item) => {
          this.managers = this.managers.filter((x) => x.value !== item.id);
          this.managers.unshift({
            value: item.id,
            text: item.name,
          });
        });
      }
      this.loading = false;
    },
    canLaunch(auction) {
      return (
        !!auction.bodyTypes.length &&
        !!auction.loadingTypes.length &&
        !!auction.dimension &&
        !!auction.startPrice &&
        !!auction.step &&
        auction.routes.reduce((ac, route) => {
          return (
            !!route.planDateOfFirstPointArrive &&
            !!route.planDateOfFirstPointLoading &&
            ac
          );
        }, true)
      );
    },
  },
};
</script>

<style lang="scss">
.auction-filter-tabs {
  margin: -1px;

  &__item {
  }
  &__input {
    display: none;

    &:checked {
      ~ .auction-filter-tabs__display {
        color: #23282c;
        background-color: #fff;
        border-color: #c8ced3 #c8ced3 #fff;
      }
    }
  }
  &__display {
    background-color: transparent;
    margin-bottom: 0;
  }
}

.text-blue {
    color: #20a8d8;
    font-size: 12px;
    font-weight: 600; 
  }

.text-standart {
  color: #5A5E65;
  font-size: 12px;
  font-weight: 600; 
}

</style>
